@import "./variables.scss";

.ut-container {
	z-index: 1;
	width: calc(100vw - #{$side_bar_width});
	overflow-x: auto;
	height: calc(100vh - #{$nav_bar_height});
	padding: 15px;
}

small {
	user-select: none;
	color: $color_danger;
	padding: 0px 0px 0px 10px;
	margin: 0px;
}

.ut-info {
	cursor: pointer;
	font-size: 12px;
	color: #bdc3c7;
}

.ut-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ut-required-field {
	font-size: 12px;
	font-weight: normal !important;
	color: $color_danger;
	transition: $transition_time;
	margin: $margin_sm;
}

.ut-scrollable {
	position: relative;
	overflow: auto;
}

.ut-w-100 {
	width: 100%;
}

.ut-w-80 {
	width: 80%;
}

.ut-w-50 {
	width: 50%;
}

.ut-h-100 {
	height: 100%;
}

.ut-shadow {
	box-shadow: rgba(2, 8, 20, 0.1) 0px 0.175em 0.5em, rgba(2, 8, 20, 0.08) 0px 0.085em 0.175em;
}

.ut-card {
	width: 100%;
	display: flex;
	padding: 15px;
	border-radius: 5px;
	background-color: #ffffff;
	box-shadow: rgba(2, 8, 20, 0.1) 0px 0.175em 0.5em, rgba(2, 8, 20, 0.08) 0px 0.085em 0.175em;
}

.ut-all-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ut-d-flex {
	display: flex;
}
.ut-responsive-flex {
	display: flex;
	flex-direction: row;
}

.ut-flex-row {
	flex-direction: row;
}

.ut-flex-column {
	flex-direction: column;
}

$flex-weights: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10);

@each $weight in $flex-weights {
	.ut-flex-#{$weight} {
		flex: $weight;
	}
}

.ut-align-items-start {
	align-items: flex-start;
}

.ut-align-items-center {
	align-items: center;
}

.ut-align-items-end {
	align-items: flex-end;
}

.ut-justify-content-start {
	justify-content: flex-start;
}

.ut-justify-content-center {
	justify-content: center;
}

.ut-justify-content-end {
	justify-content: flex-end;
}

.ut-justify-content-space-between {
	justify-content: space-between;
}
.cursor-pointer {
	cursor: pointer !important;
}
.cursor-none {
	cursor: none !important;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
	.ut-responsive-flex {
		flex-direction: column !important;
		width: 100% !important;
		margin: 0 !important;
	}
}
