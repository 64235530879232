// Global Basic Colors
$color_primary: #312783;
$color_light_primary: #7853c4;
$color_lighter_primary: #e3ddff;
$color_secondary: #db6285;
$color_success: #27ae60;
$color_warning: #fe9e25;
$color_danger: #e74c3c;
$color_info: #4a96d2;
$color_dark: #666666;
$color_light: #ecf0f1;
$color_white: #fff;
// Global App Colors
$color_disable: #f0f0f0;
$color_background: #f8fafb;
$color_placeholder: #ecf0f1;
// Global Font Size
$font_xs: 11px;
$font_sm: 14px;
$font_md: 16px;
$font_lg: 18px;
// Media Size
$media_xs: 320px;
$media_sm: 320px;
$media_md: 720px;
$media_lg: 1024px;
// Global Padding
$padding_xs: 5px 4px;
$padding_sm: 8px 9px;
$padding_md: 8px 10px;
$padding_lg: 8px 10px;
// Global Margin
$margin_xs: 1px 10px;
$margin_sm: 6px 10px;
$margin_md: 6px 10px;
$margin_lg: 6px 10px;
// Other
$block: "";
$nonblock: 200px;
$rounded_radius: 5px;
$pilled_radius: 30px;
$side_bar_width: 88px;
$nav_bar_height: 70px;
$transition_time: 0.3s;

// Exporting
:export {
	COLOR_PRIMARY: $color_primary;
	COLOR_PLACEHOLDER: $color_placeholder;
	FONT_XS: $font_xs;
	FONT_SM: $font_sm;
	FONT_MD: $font_md;
	NAV_BAR_HEIGHT: $nav_bar_height;
	SIDE_BAR_WIDTH: $side_bar_width;
}
