// Animate
.ut-popup-animate {
	animation: popup 0.7s;
}

@keyframes popup {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.4);
	}
	60% {
		transform: scale(1.1);
	}
	70% {
		transform: scale(1.2);
	}
	80% {
		transform: scale(1);
	}
	90% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}
