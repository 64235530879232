.ut-report {
	.ut-report-header {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		padding: 10px;
		.ut-report-motto {
			display: flex;
			flex-direction: column;
			align-items: center;
			> span {
				font-family: Moul;
			}
		}
	}

	.ut-report-info {
		display: flex;
		flex-direction: column;

		img {
			width: 350px;
		}
	}

	.ut-report-title {
		display: flex;
		flex-direction: column;
		align-items: center;

		> span {
			margin: 3px 0px;
			font-family: Moul;
		}
	}

	.ut-report-table {
		margin-top: 10px;
		table {
			width: 100%;
			thead {
				background-color: #e7e6e6;
				tr {
					td {
						font-size: 14px;
						font-family: "Koh_Santepheap Bold";
						text-align: center;
					}
				}
			}

			tr {
				td {
					max-width: 200px;
					font-size: 13px;
					padding: 5px;
					border: 1px solid black;
					ul {
						padding-left: 12px;
						margin: 0px;
						list-style: "- ";
					}
					ol {
						padding-left: 12px;
						margin: 0px;
					}
				}
			}
		}
	}
}
