@import "./variables.scss";

.ut-top-navbar {
	background-color: #fff;
	position: sticky;
	height: $nav_bar_height;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: $color_dark;
	font-size: 24px;
	font-weight: bold;
	top: 0;
	padding: 0 15px;
	border-bottom: 2px solid $color_light;
	z-index: 5;
}

.ut-sidebar {
	width: $side_bar_width;
	height: calc(100vh - #{$nav_bar_height});
	border-right: 2px solid $color_light;
	overflow: hidden;
	background: #fff;
}
.ut-sidebar:hover {
	overflow: auto;
}
.ut-sidebar-menu {
	border-bottom: 2px solid $color_light;
	height: 85px;
}
.ut-sidebar-menu-name {
	font-size: 15px;
	padding: 7px 5px 0 5px;
}

.ut-setting-sidebar {
	width: 250px;
}

.ut-setting-sidebar-menu {
	display: flex;
	justify-content: flex-start;
	padding: 10px 20px;
	color: #fff;
	width: 100%;
	background: $color_light_primary;
	font-size: $font-sm;
}
.ut-setting-sidebar-menu-active {
	display: flex;
	justify-content: flex-start;
	padding: 10px 20px;
	color: #fff;
	width: 100%;
	background: $color_primary;
	font-size: $font-sm;
}

/* Dropdown */
.ut-option-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.ut-dropdown-wrapper {
	box-shadow: 0px 0px 4px rgba(43, 32, 127, 0.1);
	background-color: white;
	position: absolute;
	border-radius: 5px;
	user-select: none;
	padding: 10px 0 10px 16px;
	right: 30px;
	z-index: -1;
	top: 70px;
	width: 160px;
	font-size: $font_md;
	color: $color_dark;
	cursor: pointer;
}
