@import "./variables.scss";

.ut-admin-layout {
	display: grid;
	grid-template-rows: $nav_bar_height auto;

	.ut-admin-menu {
		display: grid;
		grid-template-columns: $side_bar_width auto;

		.ut-actionbar-wrapper {
			display: grid;
			min-height: 56px;
			align-items: center;
			grid-template-columns: 310px auto;
			padding: 0px 10px;
			margin-bottom: 10px;
			border-bottom: 2px solid $color_placeholder;

			h3 {
				font-size: 20px;
				margin: 0px;
				font-weight: 600;
				color: $color_primary;
			}

			> div {
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
		}
	}
	.ut-actionbar-wrapper-center {
		display: flex;
		min-height: 56px;
		width: 100%;
		align-items: center;
		justify-content: center;
		padding: 0px 10px;
		margin-bottom: 10px;
		border-bottom: 2px solid $color_placeholder;

		h3 {
			font-size: 20px;
			margin: 0px;
			font-weight: 600;
			color: $color_primary;
		}

		> div {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}

	h3 {
		margin: 0px;
		font-weight: 600;
		color: $color_primary;
	}
}

.ut-setting-scrollable {
	overflow-x: auto;
	height: calc(100vh - 166px);
}

/*Login*/
.ut-login-container {
	display: grid;
	align-items: start;
	grid-template-columns: 58% 42%;
	width: 100vw;
	height: 100vh;
}
.ut-login-thumbnail {
	background: url("../images/leftFrame.svg");
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 100%;
	display: grid;
	place-items: center;
	place-content: center;
	h3 {
		font-size: 3rem;
		font-weight: bold;
		color: $color_white;
		text-align: center;
	}

	span {
		height: 0.3rem;
		background: $color_white;
		width: 30%;
		margin-bottom: 2%;
	}
	img {
		width: 95%;
	}
}
.ut-login-input {
	display: flex;
	height: 100%;
	flex-direction: column;
	padding: 20% 22% 0 22%;
	.ut-login-form {
		h3 {
			text-align: center;
			font-size: 2.5rem;
			font-weight: bold;
			color: $color_dark;
			margin: 5% 0;
		}
	}

	.ut-login-form > {
		span {
			font-size: 13px;
		}
	}
}

.ut-danger-message {
	margin: 4px 0 0 10px;
	justify-self: start;
	height: 5px;
	transition: $transition_time;
}
@-moz-document url-prefix() {
	.ut-danger-message {
		height: 30px;
	}
}

.ut-setting-layout {
	display: grid;
	grid-template-columns: max-content auto;
	gap: 35px;
	padding: 15px;
}

.ut-input-wrapper {
	height: calc(100vh - 180px);
	overflow: auto;
	.ut-input-layout {
		display: grid;
		grid-template-columns: repeat(3, 1fr) 15%;
		grid-template-rows: max-content;
		margin-top: 25px;
	}
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
	.ut-login-thumbnail {
		h3 {
			font-size: 2.5rem;
		}
		img {
			width: 90%;
		}
	}
}

.ut-error-wrapper {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.ut-error-card {
		border: 1px solid $color_placeholder;
		border-radius: 5px;
		min-height: 200px;
		height: auto;
		min-width: 500px;
		width: 50%;
		max-width: 800px;
		padding: 20px;
		span {
			font-size: 14px;
		}
		.ut-error-message {
			margin-top: 15px;
			width: 100%;
			min-height: 50px;
			padding: 10px;
			border: 1px solid red;
			border-radius: 5px;
			background-color: rgba(255, 0, 0, 0.15);

			i {
				color: red;
			}
		}
	}
	> span {
		padding-top: 5px;
		font-size: 11px;
		color: $color_disable;
	}
}
