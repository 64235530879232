@import "./variables.scss";
$calender: url("../icons/calendar.svg");

.ut-user-profile {
	object-fit: cover;
	height: 44px;
	width: 44px;
	border-radius: 10px;
}

ul {
	padding-left: 20px;
	outline: none;
	> li {
		width: 100%;
	}
}

// style={{ display: "flex", alignItems: "center", justifyContent: "center", width: 25, height: 25, border: "1px solid black", marginLeft: 10, borderRadius: 2 }}
.ut-bulletlist {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 25px;
	height: 25px;
	border: 1px solid $color_placeholder;
	border-radius: 5px;
	margin-left: 10px;

	&:hover {
		background-color: $color_placeholder;
	}

	&.ut-bulletlist-active {
		background-color: $color_placeholder;
	}
}

.ut-custome-textarea {
	border: 1px solid $color_light;
	border-radius: 5px;
	margin: 10px;
	min-height: 100px;

	> li {
		margin: 5px 0px;
	}
}

.ut-radio-option {
	display: flex;
	align-items: center;
	user-select: none;

	> * {
		margin: 0px;
		padding: 0px;
	}
	> input[type="radio"] {
		width: fit-content;
		margin-right: 10px;
	}

	> input[type="radio"]:checked {
		background-color: #27ae60;
	}

	> label {
		color: $color_dark;
	}
}


.ut-checkbox-option {
	display: flex;
	align-items: center;
	user-select: none;

	> * {
		margin: 0px;
		padding: 0px;
	}
	> input[type="checkbox"] {
		width: fit-content;
		
	}
	> label {
		color: $color_dark;
	}
}


#ut-qr {
	canvas {
		width: 200px;
		height: 200px;
	}
}

.rdt_TableCol_Sortable {
	div {
		white-space: nowrap;
	}
}

.ut-export-button-wrapper {
	cursor: pointer;
	display: flex;
	align-items: center;
	background-color: #27ae60;
	border-radius: 5px;
	margin: 10px;

	div {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		background-color: #fff;
		justify-content: center;
		align-items: center;
		display: flex;
		margin: 1px;
		width: 40px;
		height: 40px;
		svg {
			width: 25px;
			height: 25px;
		}
	}

	.ut-export-button {
		color: #fff;
		font-size: 13px;
		font-weight: 600;
		padding: 0px 13px;
	}
}

.ut-form-label-sm {
	padding-left: 10px;
}

.ut-login-showpassword {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 10px;

	input[type="checkbox"] {
		width: fit-content;
		margin-right: 8px;
	}
}

.ut-inTable-input::-webkit-outer-spin-button,
.ut-inTable-input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.ut-inner-input::-webkit-file-upload-button {
	visibility: hidden;
}

// Data Table
.ut-custom-sort-icon svg:last-child {
	color: #dedede !important;
	margin-left: -5px;
}

//react-flags-select
.flag-select__option__icon {
	margin-top: -14px;
}
.flag-select {
	border-radius: 5px;
	height: 35px;
	width: 70px;
	:focus {
		outline: none;
	}
}
.flag-select__options {
	margin-left: -53px;
	width: 120px;
}
.flag-select__btn {
	display: flex;
	align-items: center;
}
.flag-select__options > li {
	width: 100%;
	display: flex;
	align-items: center;
	height: 35px !important;
}

.ut-responsive-control,
.ut-responsive-button {
	width: 180px;
}

//react-bootstrap collapse override
.accordion {
	position: relative;
	button {
		width: 100%;
		margin: 0;
		padding: 0;
		outline: none;
		border: none;
		background: none;
		&:focus {
			outline: 0 !important;
		}
	}
	.collapse > a > span {
		padding-left: 40px;
	}
}

//react-toastify
.Toastify__toast {
	border-radius: 5px !important;
	box-shadow: 0px 18px 40px rgba(120, 83, 195, 0.2);
	padding: 10px 15px !important;
	height: 60px !important;
	font-family: Ubuntu !important;
}
.Toastify__close-button {
	align-self: center !important;
}
.ut-custom-toast {
	display: flex;
	font-size: $font_sm;
	align-content: center;
	line-height: 8px;
	h6 {
		font-weight: bold;
	}
	svg {
		margin-top: 10px;
		margin-right: 15px;
	}
}

::-webkit-scrollbar {
	margin-top: 10px;
	width: 0px;
	height: 15px;
}
::-webkit-scrollbar-track {
	border-radius: 7px;
}

::-webkit-scrollbar-thumb {
	border-radius: 6px;
	background-color: $color_placeholder;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
	.ut-responsive-control {
		width: 145px;
	}
	.ut-responsive-button {
		width: 140px;
	}
}
@media screen and (max-device-width: 1440px) and (-webkit-min-device-pixel-ratio: 1) {
	.ut-responsive-control {
		width: 140px;
	}
	.ut-responsive-button {
		width: 95px;
	}
}
