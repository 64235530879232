@import "./helper.scss";
$calender: url("../icons/calendar.svg");
$colors: (
	"primary": $color_primary,
	"secondary": $color_secondary,
	"success": $color_success,
	"warning": $color_warning,
	"danger": $color_danger,
	"info": $color_info,
	"dark": $color_dark,
	"light": $color_light,
	"white": $color_white,
);

@each $name, $color in $colors {
	.ut-color-#{$name} {
		color: $color;
	}

	.ut-bg-#{$name} {
		background-color: $color;
	}
}

@each $name, $color in $colors {
	.ut-button-#{$name} {
		.ut-button-left-icon,
		.ut-button-right-icon {
			color: #{color};
		}

		&.ut-button-filled {
			border: 1px solid #{$color};
			background-color: #{$color};
			transition: $transition_time;

			span,
			.ut-button-left-icon,
			.ut-button-right-icon {
				@if ($name == "light") {
					color: $color_secondary;
				} @else {
					color: dark-or-light($color);
				}
				transition: $transition_time;
			}

			&:hover {
				background-color: lighten($color, 2.5%);
			}
		}

		&.ut-button-outline {
			border: 1px solid #{$color};
			transition: $transition_time;

			span {
				white-space: nowrap;
			}

			span,
			.ut-button-left-icon,
			.ut-button-right-icon {
				color: $color;
				transition: $transition_time;
			}

			&:hover {
				background-color: #{$color};
				transition: $transition_time;

				span,
				.ut-button-left-icon,
				.ut-button-right-icon {
					color: dark-or-light($color);
					transition: $transition_time;
				}
			}
		}
	}
}

.ut-button-disabled {
	border: 1px solid #cccccc !important;
	background-color: #cccccc !important;
	transition: $transition_time;

	span {
		color: #a6a3a6 !important;
	}
}

.ut-button {
	display: flex;
	cursor: pointer;
	min-width: 100px !important;
	align-items: center;
	justify-content: center !important;
	border-radius: $rounded_radius;
	transition: $transition_time;

	span {
		user-select: none;
		font-weight: 600;
	}

	span,
	.ut-button-left-icon,
	.ut-button-right-icon {
		color: $color_primary;
	}

	.ut-button-left-icon {
		padding-right: 5px;
		transition: $transition_time;
	}

	.ut-button-right-icon {
		padding-left: 5px;
		transition: $transition_time;
	}

	&.ut-button-block {
		width: $block;
	}

	&.ut-button-nonblock {
		min-width: 70px;
		width: min-content;
	}

	&.ut-button-filled {
		span {
			white-space: nowrap;
			font-weight: 600;
		}
	}

	&.ut-button-outline {
		background-color: transparent;

		span {
			white-space: nowrap;
			font-weight: 600;
		}
	}

	&.ut-button-xs {
		padding: $padding_xs;
		margin: $margin_xs;
		transition: $transition_time;

		span {
			font-size: $font_xs;
		}
	}

	&.ut-button-sm {
		padding: $padding_sm;
		margin: $margin_sm;
		transition: $transition_time;

		span {
			font-size: $font_sm;
		}
	}

	&.ut-button-md {
		padding: $padding_md;
		margin: $margin_md;
		transition: $transition_time;

		span {
			font-size: $font_md;
		}
	}

	&.ut-button-lg {
		padding: $padding_lg;
		margin: $margin_lg;
		transition: $transition_time;

		span {
			font-size: $font_lg;
		}
	}

	&.ut-button-pilled {
		border-radius: $pilled_radius !important;
	}
}

@each $name, $color in $colors {
	.ut-input-#{$name} {
		border-color: $color_placeholder !important;

		@if $name == "white" {
			input {
				color: $color_white;
				&::placeholder {
					color: $color_white;
				}
			}
		}

		.ut-input-left-icon,
		.ut-input-right-icon {
			color: $color_placeholder !important;
			transition: $transition_time;
		}

		&:hover,
		&:focus-within {
			border-color: $color !important;
			transition: $transition_time;

			.ut-input-left-icon,
			.ut-input-right-icon {
				color: $color !important;
				transition: $transition_time;
			}
		}
	}
}

input {
	outline: none;
	width: 100%;
	border: none;
	background-color: transparent;
	&::placeholder {
		font-size: 13px;
	}
	&:disabled {
		background-color: #fff;
		&::placeholder {
			color: #757575 !important;
		}
	}
}

.ut-input-disabled {
	background-color: #fff !important;
}

.ut-input {
	font-size: 13px;
	display: flex;
	align-items: center;
	height: max-content;
	min-width: 125px;
	background-color: transparent;
	border: 1px solid;
	transition: $transition_time;

	.ut-input-left-icon {
		padding-right: 5px;
	}

	.ut-input-right-icon {
		padding-left: 5px;
	}

	&.ut-input-rounded {
		border-radius: $rounded_radius;
	}

	&.ut-input-underline {
		border-top: 0;
		border-right: 0;
		border-bottom: 1px solid;
		border-left: 0;
		border-radius: 0px;
	}

	&.ut-input-pilled {
		border-radius: $pilled_radius !important;
	}

	&.ut-input-sm {
		padding: $padding_sm;
		margin: $margin_sm;
	}

	&.ut-input-md {
		padding: $padding_md;
		margin: $margin_md;
	}

	&.ut-input-lg {
		padding: $padding_lg;
		margin: $margin_lg;
	}
}

.ut-inner-input[type="file"] {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ut-inner-input::-webkit-file-upload-button {
	width: 0px;
	height: 0px;
}

.ut-inner-input::before {
	content: "Choose File";
	display: inline-block;
	-webkit-user-select: none;
	background-color: $color_placeholder;
	white-space: nowrap;
	border-radius: 5px;
	padding: 2px 10px;
	cursor: pointer;
	font-weight: 600;
	font-size: 10pt;
	outline: none;
	width: min-content;
}

.ut-inner-input:hover::before {
	border-color: black;
}
.ut-inner-input:active::before {
	background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

// .ut-bullet-input {
// 	border: 1px solid $color_placeholder;
// 	border-radius: $rounded_radius;
// 	padding: $padding_md;
// 	margin: $margin_md;

// 	> div {
// 		display: flex;
// 		input {
// 			font-size: $font_sm;
// 		}
// 		span::before {
// 			content: "\2022";
// 			// font-size: 25px;
// 		}
// 	}
// }

.ut-bullet-input[placeholder]:empty::after {
	content: attr(placeholder);
	color: #929292;
}

.ut-textarea {
	border: 1px solid $color_placeholder;
	border-radius: $rounded_radius;
	transition: $transition_time;
	margin: $margin_sm;
	padding: $padding_sm;
	font-size: $font_sm;
	textarea {
		&:focus {
			outline: none;
		}
		border: none;
		width: 100%;
	}
	&:hover {
		border-color: $color_primary;
	}
}

.ut-float-label {
	position: absolute;
	margin-top: -5px;
	margin-left: 20px;
	padding: 0;
	background: #fff;
	font-size: $font_sm;
	z-index: 1;
}

.ut-context-menu-overlay {
	z-index: 5;
	position: fixed;
	top: 0;
	left: 0;
	background: transparent;
	width: 100vw;
	height: 100vh;
}

.ut-context-menu {
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
	min-width: 150px;
	background-color: #fff;
	border-radius: 5px;
	padding: 5px 0px;
	position: fixed;
	z-index: 5;
}

.ut-context-item {
	cursor: pointer;
	padding: 5px 10px;

	span {
		font-size: 14px;
		font-weight: 300;
		user-select: none;
	}
}

.ut-context-check {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 24px;
	height: 24px;
}

.ut-context-item:hover {
	background-color: #e8e8e8;
}

.ut-context-item > span {
	font-size: 14px;
	font-weight: 300;
}
.ut-permission-title {
	font-size: $font_md;
	font-weight: bold;
}

.ut-permission-list {
	font-size: 14px;
	display: flex;
	font-weight: 300;
	padding: 7px 35px;
	justify-content: space-between;
	border-bottom: 1px solid #e8e8e8;

	&:hover {
		background-color: #e8e8e8 !important;
	}
}

.ut-checkbox {
	margin: 0;
	display: inline-flex;
	cursor: pointer;
	position: relative;
}

.ut-checkbox > span {
	color: $color_dark;
}

.ut-checkbox input {
	height: 16px;
	width: 16px;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	appearance: none;
	border: 1px solid $color_dark;
	border-radius: 4px;
	outline: none;
	transition-duration: 0.2s;
	background-color: transparent;
	cursor: pointer;
}

.ut-checkbox > input:checked {
	border: 1px solid $color_dark;
	background-color: transparent;
}

.ut-checkbox > input:checked + span::before {
	content: "\2713";
	font-weight: 600;
	display: block;
	text-align: center;
	color: $color_dark;
	position: absolute;
	left: 3px;
	top: -2px;
	transition: all 0.1s;
}

.ut-checkbox > input:active {
	border: 2px solid $color_dark;
}

// Date Picker
.react-datepicker-wrapper,
.react-datepicker__input-container {
	width: 100%;
}
.react-datepicker__close-icon {
	position: absolute !important;
	right: 45px !important;
	&::after {
		background-color: $color_primary !important;
	}
}
.ut-custom-label-datePicker {
	font-size: $font_sm;
	margin: $margin_md;
	padding: $padding_md;
	width: 200px;
	border-radius: 5px;
	height: 40px;
	border: 1px $color_placeholder solid;
	background-color: #fff;
	background-position-x: calc(100% - 10px);
	background-position-y: center;
	background-image: $calender;
	background-repeat: no-repeat;
	position: relative;
}

.ut-custom-label-datePicker-blocked {
	font-size: $font_sm;
	margin: $margin_md;
	padding: $padding_md;
	width: 380px;
	border-radius: 5px;
	height: 40px;
	border: 1px $color_placeholder solid;
	background-color: #fff;
	background-position-x: calc(100% - 10px);
	background-position-y: center;
	background-image: $calender;
	background-repeat: no-repeat;
	position: relative;
}

.ut-custom-label-datePicker:focus,
.ut-custom-label-datePicker:hover {
	border-color: $color_primary;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
	background-color: $color_primary !important;
}

.ut-breadcrump {
	width: 100%;
	height: 63px;
	background: $color_background;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-bottom: 2px solid $color_light;
	font-size: 18px;
	font-weight: bold;
	padding-left: 15px;
	margin-bottom: 20px;
}

.ut-control-container {
	display: flex;
	justify-content: flex-end;
	height: 80px;
	align-items: center;
	flex-wrap: wrap;
	border-bottom: 2px solid $color_light;
	position: relative;
	> p {
		font-size: 1.5em;
		font-weight: bold;
		align-self: flex-end;
		position: absolute;
		left: 0;
		color: $color_primary;
	}
}
.ut-action-icon {
	display: grid;
	justify-content: center;
	align-content: center;
	grid-auto-flow: column;
	gap: 10px;
}
.ut-custom-fieldset {
	border: 1px solid #fff;
	position: relative;
	// padding: $padding_float;
	margin: 17px 10px 20px 10px;
	border-radius: 5px;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);

	legend {
		padding: 0 10px;
		width: fit-content;
		background: #fff;
		position: absolute;
		margin-top: -22px;
		font-size: $font_sm;
	}
}
.ut-fieldset-layout {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	font-size: $font_sm;
	div {
		display: grid;
		grid-template-columns: 100% max-content;
		grid-template-rows: 1fr;
		place-items: center;
		align-items: center;
		margin: 10px 0;
	}
}

.ut-dragdrop-container {
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
	// margin: $margin_float;
	padding: 5px;
	border-radius: 5px;

	.dropzone {
		border-radius: 5px;
		background: $color_light;
		padding: 25px 0;
		font-size: 18px;
		text-align: center;
		border: 2px dashed $color_placeholder;
		cursor: pointer;
		em {
			font-size: $font_sm;
		}
		&:focus {
			outline: none;
			border-color: $color_primary;
		}
	}
	a {
		text-decoration: none !important;
		color: $color_dark;
	}
	aside span {
		font-size: $font_sm;
	}
	h6 {
		text-align: center;
		color: $color_dark;
		background: $color_light;
		padding: 8px;
		border-radius: 5px;
		margin: 5px 25%;
		font-size: 12px;
		text-transform: uppercase;
		font-weight: bold;
	}
	.ut-remove-all {
		color: $color_danger;
		cursor: pointer;
		font-size: 12px;
		border-radius: 5px;
		padding: 5px;
		text-transform: uppercase;
		&:hover {
			filter: brightness(1.2);
		}
	}
}

.ut-label {
	margin: 0;
	padding-left: 10px;
}

.ut-select-list {
	height: 320px;
	overflow-x: auto;
	margin: -6px 10px 0px 10px;
	border-bottom-left-radius: $rounded_radius;
	border-bottom-right-radius: $rounded_radius;
	border: 1px solid $color_placeholder;

	> div {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: $padding_md;
		border-bottom: 1px solid $color_placeholder;

		div {
			img {
				height: 30px;
				margin-right: 10px;
				border-radius: 15px;
			}
		}

		svg {
			cursor: pointer;
		}
	}
}

/* loading */
.loading-container {
	display: grid;
	place-content: center;
	z-index: 1;
	margin-top: 25%;
}
.text-loading {
	font-weight: bold;
	text-align: center;
	letter-spacing: 2px;

	div,
	span {
		display: inline-block;
		margin: 0 -0.05em;
		color: $color_primary;
	}
	div {
		font-size: 18px;
	}
	span {
		font-size: 32px;
		letter-spacing: 8px;
	}
}
.text-loading-style {
	span {
		animation: loading01 0.8s infinite alternate;
		@for $i from 0 through 15 {
			&:nth-child(#{$i + 1}) {
				animation-delay: #{$i * 0.1}s;
			}
		}
	}
}

@keyframes loading01 {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
input:disabled {
	background: #fff;
	&:hover {
		border-color: transparent;
	}
}
@page {
	margin: 10px 10px 35px 10px;
}
@media print {
	@page {
		size: auto !important;
	}
	.ut-print-report {
		font-size: 9px !important;
		thead tr th {
			padding: 5px !important;
			font-weight: 800;
		}
		tbody tr td {
			padding: 5px !important;
			white-space: nowrap !important;
		}
	}
}
