@import "./variables.scss";

@function dark-or-light($color) {
	@if (lightness($color) > 70) {
		@return $color_light_primary;
	} @else {
		@return $color_light;
	}
}
.ut-circle-image {
	margin-left: 30px;
	object-fit: cover;
	border-radius: 15%;
	height: 50px;
	width: 50px;
}
