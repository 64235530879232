

/* @font-face {
	font-family: "Kantumruy";
	font-weight: normal;
	src: url(./assets/fonts/Kantumruy/Kantumruy-Regular.ttf);
}

@font-face {
	font-family: "Kantumruy";
	font-weight: bold;
	src: url(./assets/fonts/Kantumruy/Kantumruy-Bold.ttf);
} */

@font-face {
	font-family: "Koh_Santepheap";
	font-weight: bold;
	src: url(./assets/fonts/Koh_Santepheap/KohSantepheap-Regular.ttf);
}

@font-face {
	font-family: "Koh_Santepheap Bold";
	font-weight: bold;
	src: url(./assets/fonts/Koh_Santepheap/KohSantepheap-Bold.ttf);
}

@font-face {
	font-family: "BalooPaaji2";
	src: url(./assets/fonts/Baloo_Paaji_2/BalooPaaji2-Regular.ttf);
}

@font-face {
	font-family: "Moul";
	src: url(./assets/fonts/Moul/Moul-Regular.ttf);
}

body {
	font-family: "BalooPaaji2", "Koh_Santepheap", "Moul", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0;
	padding: 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
	width: 100vw;
	height: 100vh;
	overflow: auto;
}

.ut-credit {
	height: 100%;
	width: auto;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	padding-bottom: 10px;
}

.ut-credit > span {
	font-size: 11px;
	color: #fff;
}

@keyframes ldio-vox51pk72ko {
	0% {
		transform: translate(2px, 2px);
	}
	33.33% {
		transform: translate(102px, 2px);
	}
	66.66% {
		transform: translate(42px, 102px);
	}
	100% {
		transform: translate(2px, 2px);
	}
}

.ldio-vox51pk72ko > div {
	transform: scale(0.3);
	transform-origin: 100px 100px;
}

.ldio-vox51pk72ko > div > div {
	animation: ldio-vox51pk72ko 1.333333333333333s linear infinite;
	position: absolute;
}

.ldio-vox51pk72ko > div > div div:nth-child(1) {
	width: 72px;
	height: 72px;
	border-radius: 50%;
	border: 12px solid #312783;
	background: #ffffff;
}

.ldio-vox51pk72ko > div > div div:nth-child(2) {
	width: 17px;
	height: 51px;
	transform: rotate(-45deg);
	background: #312783;
	border-radius: 0 0 8px 8px;
	position: absolute;
	top: 68px;
	left: 85px;
}
.loadingio-spinner-magnify-ku1bo5jcz8l {
	width: 200px;
	height: 200px;
	display: inline-block;
	overflow: hidden;
	background: none;
}
.ldio-vox51pk72ko {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(1);
	backface-visibility: hidden;
	transform-origin: 0 0;
}
.ldio-vox51pk72ko div {
	box-sizing: content-box;
}
